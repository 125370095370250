import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation";
import Helmet from "react-helmet";
import "prismjs/themes/prism-okaidia.css";

export default (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  const fullYear = new Date().getFullYear();
  let { mainLink } = props;
  if (!mainLink) {
    mainLink = (
      <img
        className="icon-on-page"
        src="/icon-primary-color.svg"
        alt="WoodLooksGood Logo"
      />
    );
  }
  return (
    <div className="site-wrapper">
      <Helmet>
        <meta property="og:image" content="/logo-open-graph.svg" />
      </Helmet>
      <header className="site-header">
        <div className="site-title">
          <Link to="/">{mainLink}</Link>
        </div>
        <Navigation />
      </header>
      {props.children}
      <footer className="site-footer">
        <p>&copy; {fullYear} WoodLooksGood</p>
        <div>
          <Link to="/datenschutz">Datenschutzerklärung</Link> •{" "}
          <Link to="/impressum">Impressum</Link>
        </div>
      </footer>
    </div>
  );
};
